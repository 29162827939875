<template>
  <div class="product-filter my-12">
    <v-radio-group v-model="productType" inline class="product-radio">
      <div class="radio-part">
        <v-radio v-for="product in productTypes" :key="product" :label="`${product}`" :value="product" :ripple="false"></v-radio>
      </div>
    </v-radio-group>
    <div class="product-filter_select" :class="isVietnamese && 'product-select__vn'">
      <SelectProducts
        :key="key"
        :dataList="productList"
        :selectedData="productSelect"
        :itemCount="productList.length - 1"
        :placeholder="$t('product_dashboard_main.placeholder_select_product')"
        :hasSearch="true"
        :disabled="isGetSpecificProducts ? false : true"
        :placeHolderInput="$t('product_dashboard_main.placeholder_search_products')"
        @updateProductSelected="onHandlerUpdateProductSelected"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SelectProducts from '@/components/products/common/select-products';
import i18n from '@/lang/i18n.js'
// import {PRODUCT_DASHBOARD} from '@/constants/products/define-data';
const PRODUCT_DASHBOARD = {
  GET_ALL_PRODUCT: i18n.t('product_dashboard_main.radio_select_all_products'),
  GET_SPECIFICS_PRODUCT: i18n.t('product_dashboard_main.radio_select_specify_product'),
}
export default {
  name: 'ProductTitle',
  components: { SelectProducts },
  props: {
    isGetAllProducts: {
      type: Boolean,
      default: true,
    },
    productList: {
      type: Array,
      default: ()=> [],
    },
    productsMasterListDefault: {
      type: Array,
      default: ()=> [],
    }
  },
  data() {
    return {
      productType: null,
      productTypes: [],
      productSelect: [],
      product: null,
      key: 0,
    };
  },
  created () {
    this.productSelect = this.productsMasterListDefault;
  },
  computed: {
    ...mapState('product', ['dashboard']),
    isGetSpecificProducts() {
      return this.productType === PRODUCT_DASHBOARD.GET_SPECIFICS_PRODUCT;
    },
    isVietnamese() {
      return i18n.locale === 'vi'
    }
  },
  watch: {
    productType: {
      handler(val) {
        const isShowLineChart = this.productType === PRODUCT_DASHBOARD.GET_SPECIFICS_PRODUCT;
        if(!isShowLineChart) {
          this.productSelect = [];
        }
        this.$emit('update', val);
      }
    },
    productList() {
      this.key ++
    }
  },  
  mounted() {
    this.productTypes = Object.values(PRODUCT_DASHBOARD);
    this.productType = this.isGetAllProducts === true ? PRODUCT_DASHBOARD.GET_ALL_PRODUCT : PRODUCT_DASHBOARD.GET_SPECIFICS_PRODUCT;
  },
  methods: {
    onHandlerUpdateProductSelected(value) {
      this.productSelect = value;
      this.$emit('selectProductsMaster', value);
    }
  }
};
</script>

<style lang="scss">
.product-filter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;
  width: 100%;

  .product-radio {
    margin-top: 0;

    .v-input__control {
      .v-messages {
        display: none;
      }

      .v-input__slot {
        .radio-part {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 16px;

          .v-radio {
            margin-bottom: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 6px;

            .v-label {
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              letter-spacing: 0.03em;
              color: $monoBlack;
            }
          }
        }
      }
    }
  }

  &_select {
    width: 100%;
    padding-left: 20px;
    cursor: pointer;
  }
}

@include desktop {
  .product-filter {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 40px;
    width: 100%;

    .product-radio {
      margin-top: 0;
      padding-top: 0;

      .v-input__control {
        .v-messages {
          display: none;
        }

        .v-input__slot {
          .radio-part {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 40px;
            height: 40px;

            .v-radio {
              margin-bottom: 0;
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 0px;
              gap: 6px;

              .v-label {
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.03em;
                color: $monoBlack;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }

    &_select {
      max-width: calc(100% - (299px + 54px));
      cursor: pointer;
      padding-left: 0;
      &.product-select__vn {
        max-width: calc(100% - (365px + 54px));
      }
    }
  }
}
</style>