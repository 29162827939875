<template>
  <div>
    <div class="container-fluid chart bubble-chart bubble-chart-product">
      <wj-flex-chart chartType="Bubble" :itemsSource="dataChart" :itemFormatter="customFormatter" :tooltipContent="customTooltip" :palette="palette"
        :initialized="onChartInitialized">
        <wj-flex-chart-axis wjProperty="axisY" :title="axisYTitle" :min="0" :axisLine="true"></wj-flex-chart-axis>
        <wj-flex-chart-series binding="emission_per_product,emission_total_product"> </wj-flex-chart-series>
      </wj-flex-chart>
    </div>
    <div class="selection-unit-area">
      <v-select solo flat :items="selectDataList" no-data-text="" v-model="calSelected" label=""
        class="list-menu--select select-year  custom-select select-type select-item" :menu-props="{ contentClass: 'select-menu bulble-chart' }" />
      <v-select solo flat :items="unitDataList"  no-data-text=""  item-text="name" item-value="id" v-model="unitOfCalSelected"
        label="" class="list-menu--select select-month  custom-select select-type select-item" :menu-props="{ contentClass: 'select-menu bulble-chart' }" />

    </div>
  </div>
</template>
<script>
import '@mescius/wijmo.styles/wijmo.css';
import '@mescius/wijmo.vue2.chart';
import SelectItem from '@/components/utils/selectItem.vue';
import { getDataEmissionsForBubbleChart } from '@/api/product';
import DefaultPullDownMenu from '@/components/pulldown/DefaultPullDownMenu';
import { mapActions, mapState } from 'vuex';
import { prepareChartAxisYLineChart } from '@/concerns/chart';
import { formatBigNumber} from '@/concerns/newRegisterData/wijmo.helper';
import { addThoundSandCommaWithTwoDigit } from '@/utils/convertNumber';
export default {
  components: { SelectItem, DefaultPullDownMenu },
  props: {
    selectedTab: {
      type: Number,
      default: 0,
    },
    branchIds: {
      type: Array,
      default: () => [],
    },
    productsMasterList: {
      type: Array,
      default: () => [],
    },
    isGetAllProducts: {
      type: Boolean,
      default: false,
    },
    isHasBranchNull: {
      type: Boolean,
      default: false,
    },
    filterUpdatedKeys: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      calSelected: 1,
      selectDataList: [
        { value: 1, text: this.$t('product_dashboard_main.pulldown_select_production_quantity') },
        { value: 2, text: this.$t('product_dashboard_main.pulldown_select_production_value') },
      ],
      unitOfCalSelected: null,
      unitDataList: [],
      data: [],
      dataChart: [],
      selectedDuration: null,
      palette: ['#A9A04B'],
      chart: null,
      easing: 'EaseInQuad',
      animationMode: 'Point',
      axisYTitle: this.$t('product_dashboard_main.label_emissions_per_product'),
    };
  },
  computed: {
    ...mapState('product', ['dashboard']),

    isChangeProps() {
      // to watch month && duration
      return (
        this.filterUpdatedKeys +
        this.branchIds +
        this.productsMasterList +
        this.isGetAllProducts +
        this.isHasBranchNull +
        this.selectedTab
      );
    },
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    ...mapActions('product', ['updateEmissionsPerProducts']),
    customFormatter(engine, ht, defaultRenderer) {
      if (ht.y < 0) {
        engine.stroke = 'rgba(148, 136, 16, 0.1)';
        engine.fill = 'rgba(148, 136, 16, 0.1)';
      }
      defaultRenderer();
    },
    onChartInitialized(flexchart) {
      this.chart = flexchart;
      flexchart.bindingX = 'total_unit';
      flexchart.legend.position = 0; // remove chart legend
      flexchart.axisX.majorTickMarks = false;
      flexchart.axisY.axisLine = true;
      flexchart.axisY.majorGrid = true;
      flexchart.axisY.labelPadding = 16;
      flexchart.axisX.labelPadding = 16;
      this.updateAxisYTitle();
      flexchart.onRendered = () => {
        let textXAixElements = document.querySelectorAll('.bubble-chart-product .wj-axis-x text');
        textXAixElements.forEach(function (textElement) {
          let value = parseFloat(textElement.textContent);
          let formattedValue = value.toLocaleString('en-US');
          textElement.textContent = formattedValue;
        });
      };
    },
    handleResize() {
      this.updateAxisYTitle();
    },
    updateAxisYTitle() {
      if (window.innerWidth <= 450) {
        this.axisYTitle = '';
      } else {
        this.axisYTitle = this.$t('product_dashboard_main.pulldown_select_emissions_per_product');
      }
      if (this.chart) {
        this.chart.refresh(true);
      }
    },
    prepareAxis() {
      const calcAxisX = prepareChartAxisYLineChart(this.dataChart.map((item) => item.total_unit), 8);
      const calcAxisY = prepareChartAxisYLineChart(this.dataChart.map((item) => item.emission_per_product), 7);

      this.chart.axisY.majorUnit = calcAxisY[2];
      this.chart.axisY.min = calcAxisY[0];
      this.chart.axisY.max = calcAxisY[1];
      let max = calcAxisY[1];
      this.chart.axisY.itemFormatter = (engine, label) => {
        if (label.val >= (max)) {
          label.text = 't-CO2';
          label.cls += ' unit';

          // Remove last line grid
          var gridLines = document.querySelectorAll('.wj-gridline');
          gridLines.forEach((line, index) => {
            if (index === gridLines.length - 1) {
              line.style.display = 'none';
            }
          });
        }
        else {
          label.text = label.val.toLocaleString();
        }
        return label;
      };

      this.chart.axisX.majorUnit = calcAxisX[2];
      this.chart.axisX.min = 0;
      this.chart.axisX.max = calcAxisX[1];
      this.chart.axisX.itemFormatter = (engine, label, defaultRenderer) => {
        label.text = label.val.toLocaleString('fullwide', { useGrouping: false })
        return label;
      };
    },

    findMax(dataChart, key) {
      return dataChart.reduce((max, product) => {
        return Math.max(max, product[key]);
      }, 0);
    },
    findMin(dataChart, key) {
      return dataChart.reduce((min, product) => {
        return Math.min(min, product[key]);
      }, 0);
    },
    formatNumber(num) {
      if (['0', '0.00', 0].includes(num)) {
        return '0.00';
      }
      return addThoundSandCommaWithTwoDigit(formatBigNumber(num, 50).replace(/[^0-9\.\-]+/g, ''), '', false, true);
    },
    customTooltip(ht) {
      let item = ht.item;
      //
      return (
        `<b style="color:#A9A04B;font-size: 11px;font-weight: 700;">${item.name} </b><table class="chart-tip">` +
        `<tr><td style="padding-right: 12px;color:#4d4d4d;font-size: 11px;font-weight: 700;">${
          this.calSelected === 1
            ? this.$t('product_dashboard_main.label_production_quantity')
            : this.$t('product_dashboard_main.label_production_value')
        }</td><td style="color:#4d4d4d;font-size: 11px;font-weight: 500;">${wijmo.Globalize.format(
          item.is_null_amount ? '-' : this.formatNumber(item.total_unit_original),
        )} ${item.unit_name}</td></tr>` +
        `<tr><td style="padding-right: 12px;color:#4d4d4d;font-size: 11px;font-weight: 700;">${this.$t(
          'product_dashboard_main.label_emission_per_product',
        )}</td><td style="color:#4d4d4d;font-size: 11px;font-weight: 500;">
                    ${
                      item.is_emission_per_product_null ? '-' : this.formatNumber(item.emission_per_product_val)
                    } t-CO2</td></tr>` +
        `<tr><td style="padding-right: 12px;color:#4d4d4d;font-size: 11px;font-weight: 700;">${this.$t(
          'product_dashboard_main.label_emission_all_product',
        )}</td><td style="color:#4d4d4d;font-size: 11px;font-weight: 500;">
                    ${
                      item.emission_total_product === null ? '-' : this.formatNumber(item.emission_total_product_val)
                    } t-CO2</td></tr>` +
        `</table>`
      );
    },
    getParamsDurationOfStore() {
      let paramsStore = {};
      switch (this.selectedTab) {
        case 0:
          paramsStore.year = this.dashboard.emissionsPerProducts?.selectedData?.selectedYear?.year;
          break;
        case 1:
          const oldMonthYearSelected = this.dashboard.emissionsPerProducts?.selectedData?.selectedMonthYear;
          const [oldYear, oldMonth] = oldMonthYearSelected?.selected_range?.split('/');
          paramsStore.month = parseInt(oldMonth);
          paramsStore.year = oldYear;
          break;
        default:
          paramsStore.year = this.dashboard.emissionsPerProducts?.selectedData?.selectedYear?.year;
          break;
      }
      return paramsStore;
    },
    getDataToRenderBubble() {
      let payload = {
        type: this.selectedTab,
        branch_id: this.branchIds,
        ...this.getParamsDurationOfStore(),
      };
      const isGetAllProduct = this.dashboard.emissionsPerProducts.isGetAllProduct;
      if (!isGetAllProduct) {
        payload.product_master_id = this.productsMasterList;
      }
      payload.branch_null = this.isHasBranchNull ? 1 : null;
      //apply data to store
      this.updateDataToStore();
      getDataEmissionsForBubbleChart(payload).then((res) => {
        this.data = res;
        let quantityUnit = this.data.data?.quantity_unit || [];
        if (this.calSelected === 2) {
          quantityUnit = this.data.data?.amount_unit || [];
        }
        this.unitDataList = quantityUnit;
        this.unitOfCalSelected = quantityUnit.length ? quantityUnit[0].id : null;
        let dataChart = quantityUnit.length ? quantityUnit[0].product_master : [];
        this.dataChart = this.convertKeyToRender(dataChart);
        this.chart.refresh();
        this.prepareAxis();
      });
    },
    setDefaultData() {
      this.selectedDuration =
        this.dashboard.emissionsPerProducts.selectedType === 0
          ? this.dashboard.emissionsPerProducts.selectedData.duration_id
          : null;
    },
    updateDataToStore() {
      const selectedData = this.dashboard.emissionsPerProducts.selectedData;
      this.updateEmissionsPerProducts({
        isGetAllProduct: this.dashboard.emissionsPerProducts.isGetAllProduct,
        productMasterIds: this.dashboard.emissionsPerProducts.productMasterIds,
        selectedType: this.selectedTab,
        selectedData: {
          ...selectedData,
          duration_id: this.selectedDuration,
        },
      });
    },
    convertKeyToRender(dataChart) {
      if (dataChart.length) {
        return dataChart.map((item) => {
          if (item.hasOwnProperty('total_quantity')) {
            return {
              ...item,
              emission_total_product: Number(item.emission_total_product),
              emission_per_product: Number(item.emission_per_product),
              total_unit: Number(item.quantity),
              total_unit_original: item.quantity,
              is_emission_per_product_null: item?.emission_per_product === null,
              emission_total_product_val: this.formatNumber(item.emission_total_product),
              emission_per_product_val: this.formatNumber(item.emission_per_product),
            };
          } else {
            return {
              ...item,
              emission_total_product: Number(item.emission_total_product),
              emission_per_product: Number(item.emission_per_product),
              total_unit: Number(item.amount),
              is_null_amount: item.amount === null ? true : false,
              is_emission_per_product_null: item?.emission_per_product === null,
              emission_total_product_val: this.formatNumber(item.emission_total_product),
              emission_per_product_val: this.formatNumber(item.emission_per_product),
            };
          }
        });
      }
      return [];
    },
  },
  watch: {
    calSelected(value) {
      if (value === 2) {
        this.unitDataList = this.data.data?.amount_unit || [];
      } else {
        this.unitDataList = this.data.data?.quantity_unit || [];
      }
      if (value === 2) {
        const existIndex = this.unitDataList.findIndex((item) => item.name === '円');
        if (existIndex >= 0) {
          this.unitOfCalSelected = this.unitDataList[existIndex].id;
          this.dataChart = this.convertKeyToRender(
            this.unitDataList.length ? this.unitDataList[existIndex].product_master : [],
          );
        } else {
          this.unitOfCalSelected = this.unitDataList.length ? this.unitDataList[0].id : null;
          this.dataChart = this.convertKeyToRender(this.unitDataList.length ? this.unitDataList[0].product_master : []);
        }
      } else {
        this.unitOfCalSelected = this.unitDataList.length ? this.unitDataList[0].id : null;
        this.dataChart = this.convertKeyToRender(this.unitDataList.length ? this.unitDataList[0].product_master : []);
      }
      this.chart.refresh();
      this.prepareAxis();
    },
    unitOfCalSelected(value) {
      if (value) {
        this.dataChart = this.convertKeyToRender(
          this.unitDataList.find((item) => item.id === value)?.product_master || [],
        );
        this.chart.refresh();
        this.prepareAxis();
      }
    },
    isChangeProps: {
      handler() {
        this.getDataToRenderBubble();
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.period-combobox {
  width: 100%;
  margin-bottom: 48px;
}

.container-fluid {
  .wj-control.wj-flexchart {
    border: none;
  }
}

.selection-unit-area {
  width: 356px;
  display: flex;
  flex-direction: row;
  margin: 5px auto 0 auto;
  gap: 8px;
  .select-year {
    width: 175px;
    max-width: 175px;
  }
  .select-month {
    width: 90px;
    max-width: 90px;
  }
}
@include desktop {
  .period-combobox {
    width: 300px;
    margin-bottom: 48px;
  }
  .selection-unit-area {
    width: 328px;
    .select-year {
      width: 230px;
      max-width: 230px;
    }
    .select-month {
      width: 100px;
      max-width: 100px;
    }
  }
  .select-month {
    width: 100px;
    max-width: 100px;
  }
}
</style>
<style lang="scss" >
.bubble-chart {
  $fillBubble: rgba(148, 136, 16, 0.8);

  position: relative;

  .wj-flexchart {
    border: unset;
    position: relative;
    z-index: 1;
    max-height: 392px;

    .wj-axis-y:nth-last-child(2) {
      stroke: transparent !important;
    }

    .wj-axis-y {
      .wj-title {
        font-size: 11px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.03em;
        color: #404d50;
        font-weight: 700;
        fill: #404d50;
        // font-family: 'Source Han Sans JP';
      }

      .wj-gridline {
        stroke: rgba(42, 42, 48, 0.1);
      }

      .wj-label {
        fill: $goldMid !important;
        font-size: 11px;
        font-weight: 500;
        line-height: 18px;
        text-align: right;
        letter-spacing: 0.03em;

        &.unit {
          fill: $monoMid !important;
          font-size: 11px;
          line-height: 18px;
          text-align: right;
          letter-spacing: 0.03em;
        }
      }

      .wj-line {
        stroke: rgba(42, 42, 48, 0.1);
      }
    }

    .wj-axis-x {
      .wj-label {
        fill: $goldMid !important;
        font-weight: 500;
        font-size: 11px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.03em;
      }

      .wj-line {
        stroke: rgba(42, 42, 48, 0.1);
      }
    }

    .wj-series-group {
      ellipse:not([stroke='rgba(148, 136, 16, 0.1)']) {
        stroke-width: 0;
        fill: $fillBubble;
        stroke: $monoWhite;
        stroke-width: 1;
        stroke-opacity: 0.75;
      }
    }
  }
}
.selection-unit-area {
  .v-select__selection {
    font-size: 11px !important;
  }
  .select-item {
    height: 30px;
  }
  .v-input__control {
    height: 30px !important;
    min-height: 30px !important;
  }
  .select-month {
    .v-input__control {
      min-width: 80px;
    }
  }
  .select-year {
    .v-input__control {
      width: 240px;
    }
  }
}
.v-list-item__title {
  color: $monoBlack;
  font-size: 11px;
  font-weight: 700;
  line-height: 18px;
}
@include desktop {
  .select-item {
    .v-select__selection {
      overflow: inherit;
    }
  }
}
@media (max-width: 450px) {
  .selection-unit-area {
    width: 280px !important;
  }
  .select-year {
    .v-input__control {
      width: 200px !important;
      min-height: 28px !important;
      .v-select__selection {
        color: $monoBlack;
        font-size: 11px;
        font-weight: 700;
        line-height: 18px;
      }
    }
    .v-select__selections {
      height: 30px;
    }
  }
  .select-month {
    .v-input__control {
      width: 80px !important;
      min-height: 28px !important;
      .v-select__selection {
        font-size: 11px;
      }
    }
    .v-select__selections {
      height: 30px;
      font-size: 11px;
    }
  }
}
</style>
