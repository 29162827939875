<template>
  <div>
    <v-dialog
      v-model="dialog"
      @click:outside="handleClickOutSite"
      ref="dialog"
      width="100%"
      content-class="detail-chart-popup height-chart"
    >
      <div class="chart-popup-action">
        <div class="popup-title">{{$t('product_dashboard_main.label_breakdown_emissions')}}</div>
        <div @click="handleClose" class="chart-popup-action__close">
          <img class="menu-close" src="@/assets/icons/close-menu.svg" alt="" />
        </div>
      </div>
      <div class="chart-popup-content">
        <div class="chart-element donut-chart">
          <wj-flex-pie
            ref="chart"
            :dataLabel="false"
            :innerRadius="0.8"
            :itemsSource="detailDataChart"
            binding="emissions"
            :tooltipContent="tooltipContent"
            :palette="colors"
            :startAngle="90"
          >
            <wj-flex-chart-legend position="None"></wj-flex-chart-legend>
          </wj-flex-pie>
        </div>
        <div class="chart-head d-flex">
          <div class="chart-head-label">{{$t('product_dashboard_main.label_process')}}</div>
          <div class="chart-head-label">{{$t('product_dashboard_main.label_emissions')}}</div>
        </div>
        <div class="chart-details">
          <div class="chart-details-item" v-for="(item, i) in detailDataChart" :key="i">
            <div class="chart-details-item--legend" :style="'background-color:' + item.color"></div>
            <div class="chart-details-item--label">{{ item.process_item_name }}</div>
            <div class="chart-details-item_value ml-auto">
              {{ formatNumber(item.emissions) || '---' }} <span>tCO2</span>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import ChartDetail from '@/components/products/charts/period/chart-detail.vue';

import '@mescius/wijmo.styles/wijmo.css';
import '@mescius/wijmo.vue2.chart';
import { PALETTE_COLOR } from '@/constants/products/define-data';
import { formatValue, math, formatBigNumber } from '@/concerns/newRegisterData/wijmo.helper';
import { mapState } from 'vuex';
import {addThoundSandCommaWithTwoDigit} from '@/utils/convertNumber';

export default {
  name: 'DetailChartPopup',
  components: { ChartDetail },
  props: {
    detailDataChart: {
      type: Array,
      default: () => []
    },
    dialog: {
			type: Boolean,
			default: false,
		},
  },
  data() {
    return {
      sum: null,
      palette: PALETTE_COLOR,
    };
  },
  methods: {
    handleClickOutSite() {
      this.$emit('close')
    },
    handleClose() {
      this.$emit('close')
    },
    tooltipContent(hti) {},
    formatNumber(num) {
      if(num === 0) {
        return '---'
      }
      return addThoundSandCommaWithTwoDigit(formatBigNumber(num, 50).replace(/[^0-9\.\-]+/g, ''), '', false, true);
      return formatBigNumber(num)
        .replace(/[^0-9\.\-]+/g, '')
        .replace(/(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,'); // replace "" to null; example "0" -> 0;
    },
  },
  computed: {
    ...mapState('product', ['colors']),
  }
};
</script>
<style lang="scss" scoped>
.chart-head {
  justify-content: space-between;
  margin-top: 36px;
  border-bottom: 1px solid rgba(42, 42, 48, 0.1);
  padding: 0 8px 8px 32px;
  &-label {
    font-weight: 700;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: $monoBlack;
  }
}
.chart-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0px 0px;
  width: 100%;
  height: 140px;
  &-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 8px;
    gap: 16px;
    max-height: 22px;
    width: 100%;
    &--legend {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
    &--label {
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      letter-spacing: 0.05em;
      color: $monoBlack;
    }
    &_value {
      font-weight: 700;
      font-size: 12px;
      line-height: 22px;
      letter-spacing: 0.05em;
      color: $goldMid;
      span {
        font-weight: 400;
        margin-left: 4px;
      }
    }
  }
}

</style>