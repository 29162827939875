export const calcText = (dataList, maxLength) => {
  const stringWidth = getWidthByTextContent(dataList.toString());
  if (stringWidth > maxLength) {
    let countHideText = 0;
    countHideText = dataList.length;
    return [`${dataList.toString().replaceAll(',', ', ')}`, countHideText];
  }
  return [dataList.toString().replaceAll(',', ', '), 0];
}

const getWidthByTextContent = (string) => {
  const paddingInline = 32;
  let text = document.createElement("span");
  document.body.appendChild(text);
  text.style.width = 'auto';
  text.style.position = 'absolute';
  text.style.whiteSpace = 'no-wrap';
  text.style.font = "Source Han Sans";
  text.style.fontSize = 16 + "px";
  text.innerHTML = string;
  const width = text.clientWidth;
  document.body.removeChild(text);
  return width + paddingInline;
}