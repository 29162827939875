<template>
  <div class="duration-wrapper filter-duration">
    <div class="prev" :class="{'year-only': !isHasMonth}">
     
      <common-button class="prev__pc" @action="prev(false)" :disabled="isDisablePrev" :label="getNextAndPrevTitle.prev">
        <div class="icon-btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
              <path d="M4.01563 6L11.0156 11L11.0156 1L4.01563 6Z" stroke="currentColor" stroke-linejoin="round"/>
              <path d="M7.98438 6L14.9844 11L14.9844 1L7.98438 6Z" stroke="currentColor" stroke-linejoin="round"/>
              <path d="M1.01563 1L1.01562 11" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
      </common-button>
      <common-button class="prev__sp" @action="prev(true)" :disabled="isDisablePrev" :label="getNextAndPrevTitle.prev">
        <div class="icon-btn">
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path d="M0.515625 6L7.51562 11L7.51563 1L0.515625 6Z" stroke="currentColor" stroke-linejoin="round"/>
            <path d="M4.48438 6L11.4844 11L11.4844 1L4.48438 6Z" stroke="currentColor" stroke-linejoin="round"/>
          </svg>
        </div>
      </common-button>
    </div>

    <div class="durations-desktop" :class="{'year-only': !isHasMonth}">
      <div class="year" v-if="isHasMonth">
        <span
          v-for="(year, index) in yearsDurations"
          :key="index + '-year'"
          :style="{ flex: `0 0 ${(year.itemsCount / monthsDurations.length) * 100}%` }"
          class="text"
          :class="{ selected: selectedYear(year) }"
        >
          {{ year.value}}
        </span>
      </div>

      <div class="month" v-if="isHasMonth">
        <span
          v-for="(item, index) in monthsDurations"
          :key="index + '-month'"
          :class="{ selected: item === selectedMonthData }"
          @click="onSelectedMonth(item)"
          class="text"
          :style="{ width: `${100 / maxItemsYears}%` }"
          >{{ handleTitleDuration(item) }}</span
        >
      </div>
      <div v-if="!isHasMonth" class="only-year">
        <span
          v-for="(item, index) in years"
          :key="index + '-month'"
          class="year-item"
          @click="onSelectedYears(item)"
          :class="{ active: yearSelected === item }"
          >{{ handleTitleDuration(item) }}</span
        >
      </div>
    </div>

    <div class="durations-mobile" v-if="!isHasMonth">
      <span
        v-for="(item, index) in years"
        :key="index + '-month'"
        class="durations-mobile__text"
        @click="onSelectedYears(item)"
        :class="{ active: yearSelected === item }"
        >{{ handleTitleDurationMobile(item) }}</span
      >
    </div>
    <div class="durations-mobile" v-if="isHasMonth">
      <span
          v-for="(item, index) in monthsDurations"
          :key="index + '-month'"
          :class="{ selected: item === selectedMonthData }"
          @click="onSelectedMonth(item)"
          class="durations-mobile__text"
          :style="{ width: `100%` }"
        >{{ handleTitleDurationMobile(item) }}</span
      >
    </div>

    <div class="next" :class="{'year-only': !isHasMonth}">
      <common-button class="prev__pc" :isDisable="isDisableNext" @action="next(false)" :label="getNextAndPrevTitle.next">
        <div class="icon-btn">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.9844 11L6.98438 6V16L13.9844 11Z" stroke="currentColor" stroke-linejoin="round"/>
            <path d="M10.0156 11L3.01562 6V16L10.0156 11Z" stroke="currentColor" stroke-linejoin="round"/>
            <path d="M16.9844 16V6" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </common-button>
      <common-button class="prev__sp" :isDisable="isDisableNext" @action="next(true)" :label="getNextAndPrevTitle.next">
        <div class="icon-btn">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.4844 11L8.48438 6V16L15.4844 11Z" stroke="currentColor" stroke-linejoin="round"/>
            <path d="M11.5156 11L4.51562 6V16L11.5156 11Z" stroke="currentColor" stroke-linejoin="round"/>
          </svg>
        </div>
      </common-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'mobile';
@import 'desktop';
@media only screen and (max-width: 1420px) {
  .duration-wrapper .durations-desktop .month .text {
    width: 37px;
  }
}
</style>
<style lang="scss">
.filter-duration {
  .common-btn .v-btn__content {
    display: flex;
    flex-direction: row;
    gap: 8px;
    font-size: 11px;
  }
}

.icon-btn {
  display: flex;
  width: 20px;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@include desktop {
  .filter-duration {
    .common-btn .v-btn__content {
      flex-direction: column;
    }
  }
}
</style>
<script>
import { TABLET_WIDTH } from '@/constants/screen-size';
import CommonButton from '@/components/utils/button.vue';
import { mapActions, mapState } from 'vuex';
import { getNextPrevTitle, getDateCurrent } from '@/utils/getTextWithCondition';
import moment from 'moment';
import { formatDataList } from '@/utils/pcaf';
import { HALF_YEAR, QUARTER_YEAR } from '@/constants/pcaf';
import { convertMonthCalendar } from '@/utils/registerData';

const MAX_ITEMS = 12;
const MAX_ITEMS_YEAR = 3;
const MAX_ITEMS_MONTH_YEAR = 12;
export default {
  components: { CommonButton },
  data() {
    return {
      startIndex: 0,
      years: [],
      months: [],
      selectedMonthData: 0,
      selectedIndexYear: 0,
      isDisablePrev: false,
      isDisableNext: false,
      durationCategoryMonth: [],
      boxWidth: 0,
      resizeTimer: null,
      yearSelected: null,
      yearsData: [],
      maxItemsYears: 3,
      yearsDurations: [],
      monthsDurations: [],
      yearsDurationsData: [],
      monthsDurationsData: [],
      startMonth: null,
    };
  },
  props: {
    branchIds: {
      type: Array,
      default: () => [],
    },
    durationList: {
      type: Array,
      default: () => [],
    },
    tab: {
      type: Number,
      default: 0,
    },
    yearList: {
      type: Array,
      default: () => [],
    },
    curentYear: {
      type: Number,
      default: 0,
    },
    durationsSelectedData: {
      type: Object,
      default: () => {},
    },
    startMonthProps: {
      type: Number,
      default: 0,
    }
  },
  computed: {
    ...mapState('product', ['dashboard']),

    MAX_ITEMS: () => MAX_ITEMS,
    getNextAndPrevTitle() {
      if (window.innerWidth <= TABLET_WIDTH) {
        return {next: this.$t('product_dashboard_main.button_next_month'), prev: this.$t('product_dashboard_main.button_previous_month')}
      }
      return getNextPrevTitle(3);
    },
    selectedYear() {
      return (item) => {
        return this.selectedMonthData?.year === item.value;
      };
    },
    selectedMonthValue() {
      return this.getDurationByCategoryWithMonthly[this.startIndex];
    },
    getTitleYearMonth() {
      return (item) => {
        if(this.tab === 2) {
          return `${item.year} ${item.month}`
        }
        return `${item.year}年${item.month}`
      }
    },
    prevIcon() {
      if (this.isDisablePrev) {
        return 'img/icons/prev-duration-disable.svg';
      }

      return 'img/icons/prev-duration.svg';
    },
    nextIcon() {
      if (this.isDisableNext) {
        return 'img/icons/next-duration-disable.svg';
      }

      return 'img/icons/next-duration.svg';
    },
    isHasMonth() {
      return this.tab === 1;
    },
  },
  created() {},
  async mounted() {
    this.startMonth = this.startMonthProps;
    this.prepareYearList();
    this.selectedFirstItem();
    if (window.innerWidth <= TABLET_WIDTH) {
      this.maxItemsYears = 1;
    }
    this.onWindowResize();
  },
  watch: {
    tab: {
      handler() {
        this.prepareYearList();
        this.selectedFirstItem();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('product', ['updateEmissionsPerProducts']),
    handleTitleDurationMobile(yearSelected){ 
      let title = '';
      switch (this.tab) {
        case 0:
          title = yearSelected.year;
          break;
        case 1:
          title =  this.$t("register_data.title_select_month_year_emission", { selectedYear: yearSelected.year, selectedMonth: parseInt(yearSelected.selected_range.split('/')[1]) });
          break;
        default:
          break;
      }
      return title;
    },
    handleTitleDuration(yearSelected){ 
      let title = '';
      switch (this.tab) {
        case 0:
          title = yearSelected.year;
          break;
        case 1:
          title = this.$t('register_data.month_select', { month: convertMonthCalendar(parseInt(yearSelected.selected_range.split('/')[1]))});
          break;
        default:
          break;
      }
      return title;
    },
    setDefaultDisableNextPrev() {
      if (window.innerWidth >= TABLET_WIDTH) {
        if (this.selectedIndexYear <= 0) {
          this.isDisableNext = true;
        }
        if (this.selectedIndexYear >= this.getDurationByCategoryWithYearly.length - 1) {
          this.isDisablePrev = true;
        }
      } else {
        if (this.startIndex >= this.getDurationByCategoryWithMonthly.length - 1) {
          this.isDisableNext = true;
        }
        if (this.startIndex <= 0) {
          this.isDisablePrev = true;
        }
      }
    },
    selectedFirstItem() {
      if (!this.startMonth) {
        return;
      }
      let index = -1;
      if (this.tab === 0) {

        const oldYearSelected = this.durationsSelectedData?.selectedYear;
        index = this.yearsData.findIndex((item) => item?.year === oldYearSelected?.year);
        if (index < 0) {
          index = this.yearsData.length - 1;
          this.updateToStore(this.yearsData[index]);
        }
      } else if (this.tab === 1) {
        const oldMonthYearSelected = this.durationsSelectedData?.selectedMonthYear;
        index = this.monthsDurationsData.findIndex(
          (item) => item && item.selected_range === oldMonthYearSelected?.selected_range
        );
        if (index < 0) {
          index = this.monthsDurationsData.length - 1;
          this.updateToStore(this.monthsDurationsData[index]);
        }
      }
      let listIndex = 0;

      if (this.tab === 0) {
        this.yearSelected = this.yearsData[index];
        listIndex = this.yearsData.length - 1;
      } else {
        this.selectedMonthData = this.monthsDurationsData[index];
        listIndex = this.monthsDurationsData.length - 1;
      }

      if (index >= 0) {
        if (listIndex <= this.maxItemsYears - 1 || index <= this.maxItemsYears - 1) {
          this.selectedIndexYear = 0;
        } else if (index >= listIndex - this.maxItemsYears + 1) {
          this.selectedIndexYear = listIndex - this.maxItemsYears + 1;
        } else {
          this.selectedIndexYear = index - 1;
        }
      } else {
        this.selectedIndexYear = 0;
      }
      if (this.selectedIndexYear + this.maxItemsYears >= listIndex + 1) {
        this.isDisableNext = true;
      } else {
        this.isDisableNext = false;
      }

      if (this.selectedIndexYear <= 0) {
        this.isDisablePrev = true;
      } else {
        this.isDisablePrev = false;
      }
      this.prepareDurationsDataValue(window.innerWidth <= TABLET_WIDTH);
    },
    prepareYearList() {
      if (!this.startMonth) {
        return;
      }

      this.years = [];
      this.yearsDurationsData = [];
      this.monthsDurationsData = [];
      if (this.tab === 0) {
        this.yearsData = formatDataList(moment, this.startMonth, 'year');
        this.maxItemsYears = MAX_ITEMS_YEAR;
      } else if (this.tab === 1) {
        this.yearsDurationsData = formatDataList(moment, this.startMonth, 'duration-month-year');
        this.monthsDurationsData = formatDataList(moment, this.startMonth, 'month');
        this.maxItemsYears = MAX_ITEMS_MONTH_YEAR;
      }
      if (window.innerWidth <= TABLET_WIDTH) {
        this.maxItemsYears = 1;
      }
    },
    prepareDurationsDataValue(isMobile = false) {
      this.years = [];
      this.monthsDurations = [];
      this.yearsDurations = [];
      let index = 0;
      do {
        if (this.tab === 0) {
          this.years.push(this.yearsData[index + this.selectedIndexYear]);
        } else if(this.tab === 1) {
          if (isMobile) {
            this.monthsDurations.push(this.monthsDurationsData[index + this.selectedIndexYear]);
          } else {
            this.monthsDurations.push(this.monthsDurationsData[index + Math.floor(this.selectedIndexYear/12)*12]);
          }
        }
        index++;
      } while (index < this.maxItemsYears);
      if (this.monthsDurations.length) {
        let currentYear = this.monthsDurations[0]?.year;
        let itemsCount = 0;
        for (const data of this.monthsDurations) {
          if (data.year === currentYear) {
            itemsCount++;
          } else {
            this.yearsDurations.push({ value: currentYear, itemsCount });
            currentYear = data.year;
            itemsCount = 1;
          }
        }
        this.yearsDurations.push({ value: currentYear, itemsCount });
      }
    },
    onSelectedYears(item) {
      this.yearSelected = item;
      this.updateToStore(item);
    },
    onSelectedMonth(item) {
      this.selectedMonthData = item;
      this.updateToStore(item);
    },
    updateToStore(item) {
      let store = {};
      switch (this.tab) {
        case 0:
          store.selectedYear = item;
          break;
        case 1:
          store.selectedMonthYear = item;
          break;
        default:
          break;
      }
      this.$emit('filterUpdated', store)
    },
    getShowingDurations() {},
    next(isMobile = false) {
      this.isDisablePrev = false;
      this.isDisableNext = false;
      this.nextDesktop(isMobile);
    },
    nextDesktop(isMobile = false) {
      let lengthData;
      if (this.tab === 0) {
        lengthData = this.yearsData.length;
      } else {
        lengthData = this.monthsDurationsData.length;
      }
      if (this.selectedIndexYear + this.maxItemsYears >= lengthData) {
        this.isDisableNext = true;
        return;
      }
      this.isDisableNext = false;
      this.selectedIndexYear = this.tab === 1 ? isMobile ? this.selectedIndexYear + 1 : this.selectedIndexYear + 12 : this.selectedIndexYear + 1;
      if (this.selectedIndexYear + this.maxItemsYears >= lengthData) {
        this.isDisableNext = true;
      }
      this.prepareDurationsDataValue(isMobile);
      if (window.innerWidth <= TABLET_WIDTH) {
        if (this.tab === 0) {
          this.updateToStore(this.years[0]);
        } else {
          this.updateToStore(this.monthsDurations[0]);
        }
      }
      if (this.selectedIndexYear > 0) {
        this.isDisablePrev = false;
      } else {
        this.isDisablePrev = true;
      }
    },
    nextMobile() {},
    prev(isMobile = false) {
      this.isDisableNext = false;
      this.isDisablePrev = false;
      this.prevDesktop(isMobile);
    },
    prevDesktop(isMobile = false) {
      if (this.selectedIndexYear <= 0) {
        this.isDisablePrev = true;
        return;
      }
      this.isDisablePrev = false;
      let lengthData;
      if (this.tab === 0) {
        lengthData = this.yearsData.length;
      } else {
        lengthData = this.monthsDurationsData.length;
      }
      this.selectedIndexYear = this.tab === 1 ? isMobile ? this.selectedIndexYear - 1 : this.selectedIndexYear - 12 : this.selectedIndexYear - 1;
      if (this.selectedIndexYear <= 0 || (this.tab === 1 && (isMobile ? this.selectedIndexYear < 1 : this.selectedIndexYear < 12))) {
        this.isDisablePrev = true;
      }
      this.prepareDurationsDataValue(isMobile);
      if (window.innerWidth <= TABLET_WIDTH) {
        if (this.tab === 0) {
          this.updateToStore(this.years[0]);
        } else {
          this.updateToStore(this.monthsDurations[0]);
        }
      }
      if (this.selectedIndexYear + this.maxItemsYears < lengthData) {
        this.isDisableNext = false;
      }
    },
    onWindowResize() {
      window.addEventListener('resize', this.handleWindowResize);
    },
    offWindowResize() {
      window.removeEventListener('resize', this.handleWindowResize);
    },
    handleWindowResize(_) {
      if (window.innerWidth <= TABLET_WIDTH) {
        this.maxItemsYears = 1;
      }
      this.prepareYearList();
      this.selectedFirstItem()
    },
  },
};
</script>
