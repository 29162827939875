<template>
  <div class="chart__wrapper">
    <div class="chart">

      <!-- header -->
      <div class="chart__header">
        <div class="chart__header--left">
          <button
            type="button"
            class="btn-action"
            :disabled="isDisabledPrev"
            @click="prev"
            @mouseover="isMouseOverPrev = true"
            @mouseleave="isMouseOverPrev = false"
            @mousedown="isBlurPrev = true"
            @mouseup="isBlurPrev = false"
          >
            <img :src="prevIcon" alt="Prev"/>
          </button>
        </div>

        <div class="chart__header--right">
          <button
            type="button"
            class="btn-action"
            :disabled="isDisabledNext"
            @click="next"
            @mouseover="isMouseOverNext = true"
            @mouseleave="isMouseOverNext = false"
            @mousedown="isBlurNext = true"
            @mouseup="isBlurNext = false"
          >
            <img :src="nextIcon" alt="Next"/>
          </button>
        </div>
      </div>

      <!-- body -->
      <div class="chart__body">
        <div class="chart__body--left" ref="yearLeft" :style="{ 'min-width': minWidth }">
          <div class="axisY">
            <div
              class="axis-item"
              v-for="(item, index) in axisYItemsSource"
              :key="index + '-axis-y'"
              :class="{ unit: index === axisYItemsCount }"
              :style="{ bottom: `${item.percent - 3}%` }"
            >
              {{ item.text }}
            </div>
          </div>
        </div>

        <div class="chart__body--center">
          <div class="transition" :style="{ width: transitionWidth }"></div>

          <div
            class="chart-bar"
            @click="handleGetValueChart(item)"
            @mouseover="onMouseOver(item)"
            @mouseleave="onMouseLeave()"
            :class="{ selected: item.time_range === selectedTimeRange, hovered: item.time_range === hoverTimeRange }"
            v-for="(item, index) in chartValue"
            :key="index + '-bar-chart'"
          >
            <div class="active"></div>
            <div class="hover"></div>
            <div v-if="hasNegativeNumber" class="number-wrapper">
              <div
                v-if="item.total_emission > 0"
                class="positive-number"
                :style="{ height: `calc(100% - ${item.zero_value_percent})`, bottom: item.zero_value_percent }"
              >
                <div
                  class="bar bar--scope1 animation"
                  :style="{ height: item.emission_percent }"
                ></div>
              </div>
              <div class="zero-crossline" :style="{ height: item.zero_value_percent }"></div>
              <div v-if="item.total_emission < 0" class="negative-number" :style="{ height: item.zero_value_percent }">
                <div
                  class="bar bar--scope1 animation"
                  :style="{ height: item.emission_percent }"
                ></div>
              </div>
            </div>
            <div v-else class="number-wrapper">
              <div class="bar bar--scope1 animation" :style="{ height: item.emission_percent }"></div>
            </div>
          </div>
          <!-- <div class="chart__body--right"></div> -->
        </div>
      </div>
      <!-- footer -->
      <div class="chart__footer">
        <div class="chart__footer--left" :style="{ width: footerWidth + 'px' }">
          <button
            type="button"
            class="btn-action"
            :disabled="isDisabledPrev"
            @click="prev"
            @mouseover="isMouseOverPrev = true"
            @mouseleave="isMouseOverPrev = false"
            @mousedown="isBlurPrev = true"
            @mouseup="isBlurPrev = false"
          >
            <img :src="prevIcon" alt="Prev" />
          </button>
        </div>

        <div class="chart__footer--center">
          <div class="axisX">
            <div
              class="axis-item"
              :class="{ active: item.value === selectedTimeRange }"
              v-for="(item, index) in axisXItemsSource"
              :key="index + '-axis-x'"
            >
              <span>{{ item.label }}</span>
            </div>
          </div>
        </div>

        <div class="chart__footer--right">
          <button
            type="button"
            class="btn-action"
            :disabled="isDisabledNext"
            @click="next"
            @mouseover="isMouseOverNext = true"
            @mouseleave="isMouseOverNext = false"
            @mousedown="isBlurNext = true"
            @mouseup="isBlurNext = false"
          >
            <img :src="nextIcon" alt="Next" />
          </button>
        </div>
      </div>
    </div>
    <div class="chart-next">
      <div class="chart__header--right">
        <button
          type="button"
          class="btn-action"
          :disabled="isDisabledNext"
          @click="next"
          @mouseover="isMouseOverNext = true"
          @mouseleave="isMouseOverNext = false"
          @mousedown="isBlurNext = true"
          @mouseup="isBlurNext = false"
        >
          <img :src="nextIcon" alt="Next" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { prepareChartAxisY } from '@/concerns/chart';
import { TABLET_WIDTH } from '@/constants/screen-size';
import {
  formatValue,
  $_helper_isNumberType,
  $_helper_isNegativeNumber,
  math,
  formatBigNumber,
} from '@/concerns/newRegisterData/wijmo.helper';
import {getProductsDashboardApi} from '@/api/product';
import moment from 'moment';
import { addThoundSandCommaWithTwoDigit } from '@/utils/convertNumber';
import { getWidthYAxisByTextContent} from '@/utils/calcTextWidth';

const MAX_CHART_ITEMS = 5;

export default {
  data() {
    return {
      indexAtFirstBar: 0,
      min: 0,
      max: 500,
      axisYItemsSource: [
        {
          value: 0,
          text: '0',
        },
        {
          value: 100,
          text: '100',
        },
        {
          value: 200,
          text: '200',
        },
        {
          value: 300,
          text: '300',
        },
        {
          value: 400,
          text: '400',
        },
        {
          value: 500,
          text: 't-CO2',
        },
      ],
      axisXItemsSource: [],
      chartValue: [],
      originChartValue: [],
      selectedTimeRange: '',
      hoverTimeRange: '',
      isDisabledPrev: false,
      isDisabledNext: false,
      isMouseOverNext: false,
      isMouseOverPrev: false,
      isBlurNext: false,
      isBlurPrev: false,
      isFirstRender: true,
      transitionWidth: '100%',
      footerWidth: 0,
      minWidth: 0,
      axisYItemsCount: 7,
    };
  },
  props: {
    branchIds: {
      type: Array,
      default: () => [],
    },
    isHasBranchNull: {
      type: Boolean,
      default: false,
    }
  },
  async mounted() {
    await this.getChartData();
    if (this.indexAtFirstBar + MAX_CHART_ITEMS >= this.originChartValue.length) {
      this.isDisabledNext = true;
    } else {
      this.isDisabledNext = false;
    }

    if (this.indexAtFirstBar <= 0) {
      this.isDisabledPrev = true;
    } else {
      this.isDisabledPrev = false;
    }

    this.footerWidth = this.$refs.yearLeft.clientWidth;
  },
  computed: {
    nextIcon() {
      if (this.isDisabledNext) {
        this.isBlurNext = false;
        this.isMouseOverNext = false;
        return 'img/icons/next-disabled.svg';
      }

      if (this.isBlurNext) {
        return 'img/icons/next.svg';
      }

      if (this.isMouseOverNext) {
        return 'img/icons/next-hover.svg';
      }

      return 'img/icons/next.svg';
    },
    prevIcon() {
      if (this.isDisabledPrev) {
        this.isBlurPrev = false;
        this.isMouseOverPrev = false;
        return 'img/icons/prev-disabled.svg';
      }

      if (this.isBlurPrev) {
        return 'img/icons/prev.svg';
      }

      if (this.isMouseOverPrev) {
        return 'img/icons/prev-hover.svg';
      }

      return 'img/icons/prev.svg';
    },
    hasNegativeNumber() {
      return this.chartValue.find(item => item.total_emission < 0);
    },
  },
  watch: {
    branchIds() {
      this.getChartData();
    },
  },
  methods: {
    async getChartData() {
      const payload = {
        contractor_id: this.$store.state.userData.contractor,
        type: 1,
        branch_id: this.branchIds,
        branch_null: this.isHasBranchNull ? 1 : null,
      }
      this.originChartValue = [];
      await getProductsDashboardApi(payload).then(res => {
        res.data.forEach(item => {
          this.originChartValue.push({
            ...item,
            time_range: `${item.year}/${moment(item.month).format('M')}`,
          });
        })
        this.transformChartData();
        this.selectedFirstChartItem();
      })
    },
    handleGetValueChart(chartItem) {
      this.selectedTimeRange = chartItem.time_range;
      this.$emit('selectChart', chartItem, window.innerWidth < TABLET_WIDTH, true)
    },
    transformChartData() {
      const summary = this.originChartValue.map((item) => {
        let negativeTotal = 0;
        let positiveTotal = 0;
        if (
          $_helper_isNumberType(item.total_emission)
        ) {
          $_helper_isNegativeNumber(item.total_emission)
            ? (negativeTotal = formatBigNumber(
                math.evaluate(
                  `${math.bignumber(formatValue(negativeTotal))} + ${math.bignumber(
                    formatValue(item.total_emission),
                  )}`,
                ),
              ))
            : (positiveTotal = formatBigNumber(
                math.evaluate(
                  `${math.bignumber(formatValue(positiveTotal))} + ${math.bignumber(
                    formatValue(item.total_emission),
                  )}`,
                ),
              ));
          return [negativeTotal, positiveTotal];
        }
        return item.total_emission;
      });

      const oldSelectedMonth = this.$store.state.product.dashboard.emissionsByProducts.selectedData.month;
      const oldSelectedYear = this.$store.state.product.dashboard.emissionsByProducts.selectedData.year;
      let index = this.originChartValue.findIndex(item => item.month === oldSelectedMonth && item.year === oldSelectedYear);
      const listIndex = this.originChartValue.length - 1;
      if (index < 0) {
        index = this.originChartValue.length - 1;
      }
      if (index >= 0) {
        if (index < MAX_CHART_ITEMS) {
          this.indexAtFirstBar = index;
        } else if (index >= listIndex - 3) {
          // index in last 3 elements
          this.indexAtFirstBar = index === listIndex - 3 ? listIndex - 4 : listIndex - 3;
        } else {
          this.indexAtFirstBar = index;
        }
      } else {
        this.indexAtFirstBar = listIndex - 4;
      }
      [this.axisYItemsSource, this.min, this.max] = prepareChartAxisY(summary, this.axisYItemsCount);
      this.prepareChartDataValue();
    },
    selectedFirstChartItem() {
      if (!this.originChartValue.length) {
        return;
      }
      const oldSelectedMonth = this.$store.state.product.dashboard.emissionsByProducts.selectedData.month;
      const oldSelectedYear  = this.$store.state.product.dashboard.emissionsByProducts.selectedData.year;
      let index = this.originChartValue.findIndex((item) => {
        return item.month === oldSelectedMonth && item.year === oldSelectedYear;
      });
      if (index < 0) {
        index = this.originChartValue.length - 1;
      }
      if (index >= 0) {
        const item = this.originChartValue[index];
        this.selectedTimeRange = item.time_range;
        this.$emit('selectChart', item, window.innerWidth < TABLET_WIDTH, false)
      } else {
        const latestDurationIndex = this.originChartValue.length - 1;
        const item = this.originChartValue[latestDurationIndex];
        this.selectedTimeRange = item.time_range;
        this.$emit('selectChart', item, window.innerWidth < TABLET_WIDTH, false)
      }

      this.isDisabledNext = this.indexAtFirstBar + MAX_CHART_ITEMS >= this.originChartValue.length;

      this.isDisabledPrev = this.indexAtFirstBar <= 0;
    },
    prepareChartDataValue() {
      if (!this.originChartValue.length) {
        return;
      }

      let index = 0;
      let chartValueOriginal = [];
      if(this.originChartValue.length <= MAX_CHART_ITEMS) {
        this.indexAtFirstBar = 0
      }
      do {
        chartValueOriginal.push(this.originChartValue[index + this.indexAtFirstBar]);
        index++;
      } while (index < MAX_CHART_ITEMS);

      chartValueOriginal = chartValueOriginal.filter((item) => !!item);

      this.axisXItemsSource = chartValueOriginal.map((item) => {
        return {
          label: item.time_range,
          value: item.time_range,
          start_at: item.year,
          end_at: item.month,
        };
      });

      this.chartValue = chartValueOriginal.map((item) => {
        return {
          ...item,
          emission_percent: '0%',
        };
      });

      const timeout = this.isFirstRender ? 1000 : 0;

      setTimeout(() => {
        const summary = chartValueOriginal.map((item) => {
          let negativeTotal = 0;
          let positiveTotal = 0;
          if (
            $_helper_isNumberType(item.total_emission)
          ) {
            $_helper_isNegativeNumber(item.total_emission)
              ? (negativeTotal = formatBigNumber(
                  math.evaluate(
                    `${math.bignumber(formatValue(negativeTotal))} + ${math.bignumber(
                      formatValue(item.total_emission),
                    )}`,
                  ),
                ))
              : (positiveTotal = formatBigNumber(
                  math.evaluate(
                    `${math.bignumber(formatValue(positiveTotal))} + ${math.bignumber(
                      formatValue(item.total_emission),
                    )}`,
                  ),
                ));
            return [negativeTotal, positiveTotal];
          }
          return item.total_emission;
        });
        [this.axisYItemsSource, this.min, this.max] = prepareChartAxisY(summary, this.axisYItemsCount);
        this.getWidthOfText();
        this.chartValue = chartValueOriginal.map((item) => {
          return {
            ...item,
            emission_percent: this.calcScopeEmission(item.total_emission),
            zero_value_percent: Math.abs(Number(this.min) / (this.max - this.min)) * 100 + '%',
          };
        });
      }, timeout);

      setTimeout(() => {
        this.transitionWidth = 0;

        this.footerWidth = this.$refs.yearLeft.clientWidth;
      }, timeout + 500);

      if (this.isFirstRender) {
        setTimeout(() => {
          this.isFirstRender = false;
        }, timeout + 2000);
      }
    },
    onMouseOver(item) {
      this.hoverTimeRange = item.time_range;
    },
    onMouseLeave() {
      this.hoverTimeRange = '';
    },
    next() {
      if (this.indexAtFirstBar + MAX_CHART_ITEMS >= this.originChartValue.length) {
        this.isDisabledNext = true;
        return;
      }

      this.isDisabledNext = false;
      this.indexAtFirstBar = this.indexAtFirstBar + 1;

      if (this.indexAtFirstBar + MAX_CHART_ITEMS >= this.originChartValue.length) {
        this.isDisabledNext = true;
      }
      this.prepareChartDataValue();

      if (this.indexAtFirstBar > 0) {
        this.isDisabledPrev = false;
      } else {
        this.isDisabledPrev = true;
      }
    },
    prev() {
      if (this.indexAtFirstBar <= 0) {
        this.isDisabledPrev = true;
        return;
      }

      this.isDisabledPrev = false;

      this.indexAtFirstBar = this.indexAtFirstBar - 1;
      if (this.indexAtFirstBar <= 0) {
        this.isDisabledPrev = true;
      }
      this.prepareChartDataValue();

      if (this.indexAtFirstBar + MAX_CHART_ITEMS < this.originChartValue.length) {
        this.isDisabledNext = false;
      }
    },
    calcScopeEmission(emissionsValue) {
      if (emissionsValue < 0) {
        return Math.abs(Number(emissionsValue) / this.min) * 100 + '%';
      } else {
        return Math.abs(Number(emissionsValue) / this.max) * 100 + '%';
      }
    },
    getWidthOfText() {
      const  text = this.axisYItemsSource[0]?.text.length > addThoundSandCommaWithTwoDigit(this.axisYItemsSource[5]?.value.toString().length, "", false, true)
        ? this.axisYItemsSource[0]?.text 
        : this.axisYItemsSource[5]?.text?.toString();      
      const width = getWidthYAxisByTextContent(text);
      this.minWidth = width < 60 ? '60px' : width + 2 + 'px';
    },
  },
};
</script>
<style lang="scss" scoped>
@import './index.scss';
.chart__wrapper {
  .chart__body {
    .chart__body--left {
      min-width: 53px;
      .axisY {
        justify-content: unset;
        position: relative;
        .axis-item {
          position: absolute;
          right: 0;
        }
      }
    }
    .chart__body--center {
      .number-wrapper {
        width: 100%;
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
        position: relative;
        .positive-number {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-direction: column;
          position: absolute;
        }
        .negative-number {
          flex: 1;
          position: absolute;
        }
        .zero-crossline {
          border-top: 1px solid #ddd;
          height: 1px;
          width: 100%;
          position: absolute;
        }
      }
    }
  }
}
</style>

