<template>
  <div>
    <v-dialog
      v-model="dialog"
      @click:outside="handleClickOutSite"
      ref="dialog"
      width="100%"
      content-class="detail-chart-popup"
    >
      <div class="chart-popup-content">
        <ChartDetail :chartDetail="detailDataChart" :isDetailPopup="true"></ChartDetail>
      </div>
      <div class="chart-popup-action">
        <div @click="handleClose" class="chart-popup-action__close">
          <img class="menu-close" src="@/assets/icons/close-menu.svg" alt="" />
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import ChartDetail from '@/components/products/charts/period/chart-detail.vue';
export default {
  name: 'DetailChartPopup',
  components: { ChartDetail },
  props: {
    detailDataChart: {
      type: Object,
      default: () => {}
    },
    dialog: {
			type: Boolean,
			default: false,
		},
  },
  methods: {
    handleClickOutSite() {
      this.$emit('close')
    },
    handleClose() {
      this.$emit('close')
    }
  }
};
</script>

<style>
</style>