<template>
  <div class="tabs-header tab-product-period">
    <v-tabs v-model="selectedTab" color="#404D50" background-color="transparent">
      <v-tab>{{ $t('product_dashboard_main.tab_by_registration_year') }}</v-tab>
      <v-tab>{{ $t('dashboard_main.tab_by_month') }}</v-tab>
    </v-tabs>
  </div>
</template>

<script>
export default {
  name: 'TabHeader',
  props: {
    tab: {
      type: Number,
      default: 0
    }
  },
  computed: {
    selectedTab: {
      get() {
        return this.tab;
      },
      set(value) {
        this.$emit('update:tab', value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.tab-product-period {
  width: 334px !important;
}
.tabs-header {
  width: 100%;
}
@include desktop {
  .tabs-header {
    width: max-content;
  }
}
</style>