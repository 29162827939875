<template>
  <div class="product-title p-0">
    <div class="product-title-content">
      <img :src="getIcon" alt="title"/>
      <h2 class="product-title-content__label">{{ label }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductTitle',
  props: {
    label: {
      type: String,
      default: ''
    },
    iconPath: {
      type: String,
      default: 'page-title-icon.svg'
    },
    isPcafSetting: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    getIcon() {
      if (this.isPcafSetting) {
        return require(`@/assets/icons/dashboard/pcaf/${this.iconPath}`);
      }
      return require(`@/assets/icons/product/${this.iconPath}`);
    },
  },
}
</script>

<style lang="scss" scoped>
.product-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  margin-top: 48px;
  &-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    width: 100%;
    &__label {
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.1em;
      color: $monoBlack;
    }
  }
}
@media (max-width: 450px) {
  .product-title {
    margin-top: 0px;
  }
}
</style>