<template>
  <div class="padding-top-layout mr-md-40-px">
    <page-title class="padding-left-layout mb-20-px mt-header-title" :title="$t('product_dashboard_main.title_dashboard')"/>
    <div class="dashboard-home">
      <div class="dashboard-filter">
        <dashboard-filter @update="handlerUpdateBranchIds" />
      </div>
      <div class="emission-by-period padding-left-layout">
        <products-by-period
          v-if="isLoading"
          :durationList="durationList"
          :branchIds="branchIds"
          :productList="getProductListByBranch"
          :isHasBranchNull="isHasBranchNull"
        />
      </div>
      <div class="emission-by-category padding-left-layout">
        <products-by-bubble
          v-if="isLoading"
          :durationList="durationList"
          :branchIds="branchIds"
          :productList="getProductListByBranch"
          :isHasBranchNull="isHasBranchNull"
          :startMonthProps="startMonth"
          :yearList="yearList"
        />
      </div>
      <div class="emission-by-category padding-left-layout">
        <products-with-high-emissions
          v-if="isLoading"
          :durationList="durationList"
          :yearList="yearList"
          :branchIds="branchIds"
          :isHasBranchNull="isHasBranchNull"
          :startMonthProps="startMonth"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapState} from 'vuex';
import { ROUTES } from '@/router/constants';
import PageTitle from '@/components/pageTitle/pageTitle.vue';
import DashboardFilter from '@/components/products/multiple-filter/index.vue';
import ProductsByPeriod from '@/views/products/dashboard/products-by-period/index.vue';
import ProductsByBubble from '@/views/products/dashboard/products-by-bubble/index.vue';
import ProductsWithHighEmissions from '@/views/products/dashboard/products-with-high-emissions/index.vue';
import { getDurations } from '@/api/duration';
import { getProductMasterApi } from '@/api/product';
import moment from 'moment';
import { calcYearFromStartMonthCustome } from '@/utils/registerData';
import { getStartMonth } from "@/api/duration";
export default {
  components: {
    PageTitle,
    DashboardFilter,
    ProductsByPeriod,
    ProductsByBubble,
    ProductsWithHighEmissions
  },
  data() {
    return {
      items: [
        {
          text    : 'ダッシュボード',
          disabled: true,
          href    : ROUTES.PRODUCTS,
        },
      ],
      durationList: [],
      productList: [],
      isLoading: false,
      branchIds: [],
      isHasBranchNull: false,
      yearList: [],
      startMonth: null
    };
  },
  async mounted() {
    this.isLoading = false;
    const items =  [
      {
        text: this.$t("product_dashboard_main.label_home"),
        disabled: true,
        href: ROUTES.PRODUCTS,
      },
    ]
    this.updateBreadCrumb(items);
    await Promise.all([this.handleGetStartMonth(), this.getListProductMaster()]);
    this.isLoading = true;
  },
  computed: {
    ...mapState('userData', ['currentUser']),
    getProductListByBranch() {
      if(this.isHasBranchNull) { //case select branch null
        let productByBranch = null
        if(this.branchIds.length <= 0){ // case dont select any branch
          productByBranch = this.productList.filter(product => !product?.branch_id)
        } else { // has select some branch
          productByBranch = this.productList.filter(product => this.branchIds.includes(product?.branch_id) || product?.branch_id === null)
          const checkBoxAll = {
            hasCheckbox: true,
            text: this.$t('b_export_report.checkbox_select_all')
          }
          productByBranch.unshift(checkBoxAll);
        }
        return productByBranch
      } else { // case don't select branch null
        let productByBranch = null
        if(this.branchIds.length > 0){
          productByBranch = this.productList.filter(product => product?.branch_id && this.branchIds.includes(product?.branch_id))
          const checkBoxAll = {
            hasCheckbox: true,
            text: this.$t('b_export_report.checkbox_select_all')
          }
        productByBranch.unshift(checkBoxAll);
        return productByBranch
        } else {
          return this.productList
        }
      }
    }
  },
  methods : {
    ...mapActions('commonApp', ['updateBreadCrumb']),
    async getListDurations() {
      await getDurations({ contractor_id: this.currentUser.user.contractor_id }).then(res => {
        res.data.forEach(item => {
          let duration = {
            ...item,
            value: item.id,
            name: `${moment(item.start_at).format('YYYY/M')} - ${moment(item.end_at).format('YYYY/M')}`,
            text: `${moment(item.start_at).format('YYYY/M')} - ${moment(item.end_at).format('YYYY/M')}`,
          };
          this.durationList.push(duration);
        });
      })
    },
    async getListProductMaster() {
      await getProductMasterApi().then(res => {
        this.productList = res.data.map(item => {
          return {
            ...item,
            hasCheckbox: true,
            text: item.name
          }
        })
        const checkBoxAll = {
          hasCheckbox: true,
          text: this.$t('b_export_report.checkbox_select_all')
        }
        this.productList.unshift(checkBoxAll);
      })
    },
    handlerUpdateBranchIds(value, isHasBranchNull) {
      this.branchIds = value;
      this.isHasBranchNull = isHasBranchNull;
    },
    async handleGetStartMonth() {
      try {
        const data = await getStartMonth(this.$store.state.userData.contractor)
        this.startMonth = data?.data?.start_month
        this.yearList = calcYearFromStartMonthCustome(this.startMonth, 2017);
      } catch (error) {
        
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.emission-by-period {
  .period-header {
    margin-top: 40px;
  }
}

.mt-header-title{
  margin-top: 76px;
}

.mb-20-px {
  margin-bottom: 20px;
}

.mr-md-40-px {
  margin-right: 0;
}

.emission-category {
  display: flex;
  justify-content: center;
}

.dashboard-filter {
  left: 0;
  position: fixed;
  top: 84px;
  z-index: 99;
  width: 100%;
  padding-left: 20px;
  background: $monoOffWhite;
  border-top: 1px solid rgba(42, 42, 48, 0.1);
}

@include desktop {
  .mt-header-title{
    margin-top: 40px;
  }
  .emission-by-period {
    .period-header {
      margin-top: 48px;
    }
  }
  .mr-md-40-px {
    margin-right: 40px;
  }
  .dashboard-filter {
    left: unset;
    padding-left: 40px;
    border-top: unset;
  }
}
</style>