<template>
  <div class="chart-details" :class="isDetailPopup && 'chart-popup'">
    <div class="chart-details-duration">
      <div class="chart-details-duration__label">{{ $t('product_dashboard_main.label_period') }}</div>
      <div class="chart-details-duration__detail">{{ getDuration }}</div>
    </div>

    <div class="chart-details-content">
      <div class="chart-details-content_emission">
        <div class="chart-details-content_emission__label">{{ $t("dashboard_main.label_total_emissions") }}</div>
        <div class="chart-details-content_emission__value">
          {{ getValueEmissions === 0 ? '-' : formatNumber(getValueEmissions) }}
          <span class="chart-details-content_emission__value--unit">t-CO2</span>
        </div>
      </div>

      <div class="chart-details-content_variation">
        <div class="chart-details-content_variation__label">{{getDescriptionText}}</div>
        <div class="chart-details-content_variation__content">
          <span class="chart-details-content_variation__content--value">{{ formatNumber(getRatio, 'chart-detail-ratio') }}</span>
          <span v-if="getRatio && getRatio !== '-'" class="chart-details-content_variation__content--percent">%</span>
          <img v-if="isUpEmissions && getRatio && getRatio !== '-'" src="img/icons/up.svg" class="chart-details-content_variation__content--icon">
          <img v-if="!isUpEmissions && getRatio && getRatio !== '-'" src="img/icons/down.svg" class="chart-details-content_variation__content--icon">
        </div>
      </div>

      <div v-if="chartType === 'line'" style="width: 100%;">
        <div class="chart-details-content_product" v-for="(product, index) in productNames" :key="index">
          <div class="chart-details-content_product_item">
            <div class="chart-details-content_product_item--dot" :style="'background: ' + product.color + ';'"></div>
            <div class="chart-details-content_product_item--name">{{ product.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatBigNumber } from '@/concerns/newRegisterData/wijmo.helper';
import { addThoundSandCommaWithTwoDigit } from '@/utils/convertNumber';
export default {
  name: 'ChartDetail',
  computed: {
    getDuration() {
      return this.chartDetail?.time_range;
    },
    getValueEmissions() {
      return this.chartDetail?.total_emission
    },
    isUpEmissions() {
      return this.chartDetail?.up === 1 ? true : false;
    },
    getRatio() {
      return this.chartDetail?.ratio || "-"
    },
    getDescriptionText() {
      return this.chartDetail.type === 0 ? this.$t('product_dashboard_main.label_yoy_change') : this.$t('product_dashboard_main.label_month_to_month_basis');
    }
  },
  props: {
    chartDetail: {
      type: Object,
      default: () => {}
    },
    isDetailPopup: {
      type: Boolean,
      default: false
    },
    productNames: {
      type: Array,
      default: () => []
    },
    chartType: {
      type: String,
      default: ''
    }
  },
  methods: {
    formatNumber(num, type) {
      if(num === 0) {
        return '---'
      }
      return addThoundSandCommaWithTwoDigit(num, type, false, true)
    },
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
.chart-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 32px;
  background: $monoWhite;
  border-radius: 8px;
  max-width: 45%;
  // margin-left: 54px;
  &.chart-popup {
    max-width: 100%;
    background: transparent;
  }
  &-duration {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    &__label {
      font-weight: 700;
      font-size: 11px;
      line-height: 18px;
      letter-spacing: 0.03em;
      color: $monoBlack;
    }
    &__detail {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $goldMid;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    &_emission {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px 0px 16px;
      gap: 8px;
      border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      width: 100%;
      &__label {
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: $monoBlack;
      }
      &__value {
        font-weight: 400;
        font-size: 30px;
        line-height: 36px;
        letter-spacing: 0.03em;
        color: $goldMid;
        word-break: break-all;
        &--unit {
          font-size: 14px;
          line-height: 24px;
          margin-left: 8px;
          display: inline-block;
        }
      }
    }

    &_variation {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 0px;
      gap: 8px;
      width: 100%;
      // border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      &__label {
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: $monoBlack;
      }
      &__content {
        font-weight: 400;
        font-size: 30px;
        line-height: 36px;
        letter-spacing: 0.03em;
        color: $goldMid;
        display: flex;
        align-items: center;
        gap: 8px;
        // height: 22px;
        &--value {
          word-break: break-all;
        }
        &--percent {
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.05em;
          margin-top: 12px;
        }
      }
    }

    &_ratio {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 0px;
      gap: 8px;
      &__label {
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: $monoBlack;
      }
      &__content {
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: 0.05em;
        color: $goldMid;
        display: flex;
        align-items: center;
        gap: 8px;
        height: 22px;
        &--icon {
          cursor: pointer;
        }
        &--percent {
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.05em;
        }
      }
    }

    &_product {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      width: 100%;
      &_item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 8px;
        width: 100%;
        &--dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          min-width: 10px;
        }
        &--name {
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          letter-spacing: 0.05em;
          color: $monoBlack;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
        }
      }
    }
  }
}
</style>